import React from 'react'

const Footer = () => {
  return (
    <div>
        
        
<footer id='footer' class="dark_bg">
<div class="container ">
<div class="row">
<div class="col-lg-4 col-md-4 footer_link pr-5">
<h6>Information</h6>
<p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut.</p>

</div>

<div class="col-md-2 col-6 footer_link">
<h6>Quick Links</h6>

<li><a href="#">About Us </a></li>
<li><a href="#">Resources</a></li>
<li><a href="#">Team</a></li>
<li><a href="#">Contact</a></li>

</div>
<div class="col-md-2 col-6 footer_link">
<h6 >Other Links</h6>
<li ><a href="#">Resources </a></li>
<li><a href="#">Charity</a></li>
<li><a href="#">Referral</a></li>
<li><a href="#">Support</a></li>

</div>
<div class="col-md-4 col-12 footer_link bottom_social">
<h6>Join the community</h6>
<p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut.</p>
<br/>
<a href="#" class="text-center"><i class="fa fa-paper-plane-o"></i> </a>
<a href="#" class="text-center"><i class="fa fa-twitter"></i> </a>
<a href="#" class="text-center"><i class="fa fa-facebook"></i> </a>
<a href="#" class="text-center"><i class="fa fa-linkedin"></i> </a>
<a href="#" class="text-center"><i class="fa fa-youtube-play"></i> </a>
</div>


</div>



</div>
</footer>

    </div>
  )
}

export default Footer