import { useEffect, useState } from 'react';
import { CHAINS, SUPPORTED_CHAINS } from '../constants/blockchainConstants';



const useMetaMask = () => {
  const { BSC_CHAIN_ID, SEPOLIA_CHAIN_ID, ETH_CHAIN_ID } = SUPPORTED_CHAINS;
  const [isConnected, setIsConnected] = useState(false);
  const [account, setAccount] = useState(null);
  const [provider, setProvider] = useState(null);
  const [networkId, setNetworkId] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (window.ethereum) {
      const ethProvider = window.ethereum;
      setProvider(ethProvider);
    } else {
      setError('MetaMask not installed');
    }
  }, []);

  useEffect(() => {
    if (provider) {
      provider.on('accountsChanged', handleAccountsChanged);
      provider.on('chainChanged', handleChainChanged);

      checkConnection();

      return () => {
        provider.removeListener('accountsChanged', handleAccountsChanged);
        provider.removeListener('chainChanged', handleChainChanged);
      };
    }
  }, [provider])
  const checkConnection = async () => {
    try {
      const accounts = await provider?.request({ method: 'eth_accounts' });
      if (accounts?.length > 0) {
        setIsConnected(true);
        setAccount(accounts[0]);
        const networkId = await provider?.request({ method: 'net_version' });
        setNetworkId(networkId);
      } else {
        setIsConnected(false);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const connect = async () => {
    try {
      const accounts = await provider?.request({ method: 'eth_requestAccounts' });
      setIsConnected(true);
      setAccount(accounts[0]);
      const networkId = await provider?.request({ method: 'net_version' });
      setNetworkId(networkId);
    } catch (err) {
      setError(err.message);
    }
  };

  const disconnect = () => {
    setIsConnected(false);
    setAccount(null);
    setNetworkId(null);
  };

  const handleAccountsChanged = (accounts) => {
    if (accounts?.length > 0) {
      setAccount(accounts[0]);
      setIsConnected(true);
    } else {
      disconnect();
    }
  };

  const handleChainChanged = (chainId) => {
    setNetworkId(chainId);
  };

  const switchChain = async (chainId) => {
    try {
      await provider?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId }],
      });
      setNetworkId(chainId);
    } catch (error) {
      // Handle error if the chain is not added yet
      if (error.code === 4902) {
        try {
          const prms = CHAINS[chainId]
          const params = {
            chainId: chainId,
            chainName: prms.chainName,
            nativeCurrency: prms.nativeCurrency,
            rpcUrls: prms.rpcUrls,
            blockExplorerUrls: prms.blockExplorerUrls
          }
          await provider?.request({
            method: 'wallet_addEthereumChain',
            params: [params],
          });
          setNetworkId(chainId);
        } catch (addError) {
          setError(addError.message);
        }
      } else {
        setError(error.message);
      }
    }
  };

  const switchToEthereum = () => switchChain(ETH_CHAIN_ID);
  const switchToSepolia = () => switchChain(SEPOLIA_CHAIN_ID);
  const switchToBSC = () => switchChain(BSC_CHAIN_ID);

  return {
    isConnected,
    account,
    networkId,
    error,
    connect,
    disconnect,
    switchToEthereum,
    switchToSepolia,
    switchToBSC,
    provider
  };
};

export default useMetaMask;
