import React from 'react';
import { Modal, } from 'react-bootstrap';

export const MetaMaskPrompt = ({ show, handleClose }) => {
  const handleInstall = () => {
    window.open('https://metamask.io/download.html', '_blank');
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Install MetaMask</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          It looks like you don't have the MetaMask extension installed. Please install it to interact with our application.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button className="yellow_btn" onClick={handleClose}>
          Close
        </button>
        <button className="green_btn" onClick={handleInstall}>
          Install MetaMask
        </button>
      </Modal.Footer>
    </Modal>
  );
};


