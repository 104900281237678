
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import './css/Style.css';
import { BuyComponent } from './pages/Buy';
import Home from './pages/Home';


function App() {
  return (
    <>
      <BrowserRouter>
 
      <Routes>

      <Route path="/" element={<Home/>} > </Route>
      <Route path="/home" element={<Home />} />
          <Route path="/home/:section" element={<Home />} />
          <Route path="/buy" element={<BuyComponent />} />
    </Routes>
    
    </BrowserRouter>
  
    
    </>
  );
}

export default App;
