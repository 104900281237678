import React,{useEffect} from "react";
import feature_1 from "../images/feature_1.png";
import yellowshadow from "../images/yellowshadow.png";
import Header from "../Component/Header";
import global_network from '../images/global_network.png'
import BuyToken from "../Component/buy/buyToken";
import Footer from '../Component/Footer'
import WOW from 'wowjs';
import 'wowjs/css/libs/animate.css';

export const BuyComponent = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  return (
    <div>
        <Header/>
        <section id="top_banner">
        <div class="container">
          <div class="row">
            <div class="col-md-6 banner_txt wow fadeInLeft mt-3 pe-5" data-wow-duration="1.5s">
              <h1 style={{ fontSize: "54px"}}>Buy BHARAT token</h1>
              <p style={{ color:"#515152"}}>BHARAT is a virtual reality platform powered by the Ethereum blockchain.</p>
            </div>
          </div>
        </div>

        <div id="home_wave">
        </div>

        <img src={feature_1} alt="img" class="shade_bg" />
        <img src={yellowshadow} alt="img" class="shadow_img" />

      </section>
      <section id="about-us">
        <div class="container text-white">
          {/* <h1 className='text-center'>About Us</h1> */}
          <div class="row">
            <div class="col-md-8 wow fadeInUp" data-wow-duration="3s">
              <br />
              <BuyToken/>
            </div>
            <div class="col-md-4 points wow fadeInUp pt-5" data-wow-duration="10s">
              <img class="banner-img mt-5" src={global_network} alt="" />
            </div>

          </div>

        </div>
      </section>
      <Footer />
    </div>
  );
};
