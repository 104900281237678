import React,{useEffect} from 'react'
import { Link,useParams } from 'react-router-dom'

const Header = () => {
  const {section} = useParams()
  useEffect(() => {
   if(section){
    const element = document.getElementById(section)
    element.scrollIntoView({behavior:"smooth",block:"start"})
   }
  }, [section])
  
  const scrollToSection = (route)=>{
    
  }
  return (
    <div>
        <header>
  <nav class="navbar navbar-expand-md fixed-top dark_bg">
    <div class="container container-fluid side_space"> <a class="navbar-brand" onClick={()=> scrollToSection("/")}><img src="../images/logo.png" alt="logo" className='logoimg'/></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation"> <span class="navbar-toggler-icon"><i class="fa fa-bars"></i> </span> </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarsExampleDefault">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item active"> <Link class="nav-link" to="/buy">Buy </Link> </li>
          <li class="nav-item active"> <Link class="nav-link" to="/home/about-us">About Us </Link> </li>
          <li class="nav-item"> <Link class="nav-link" to="/home/feature">Use Cases</Link> </li>  
		  <li class="nav-item"> <Link class="nav-link" to="/home/address_link">Our Project & Ecosystem</Link> </li>  
		  <li class="nav-item"> <Link class="nav-link" to="/home/roadmap">Roadmap</Link> </li>  
		  <li class="nav-item"> <Link class="nav-link" to="/home/token-distribution">Token Distribution</Link> </li> 
		  <li class="nav-item"> <Link class="nav-link" to="/home/token_sale_06">ICO</Link> </li> 
		   {/* <li class="nav-item"> <Link class="nav-link" to="/home/people">Team</Link> </li> */}
			<li class="nav-item"> <Link class="nav-link" to="/home/footer">Contact</Link> </li>
        </ul>
		<ul class="navbar-nav">         
		<li class="nav-item"><a class="nav-link red_btn" href="#">Login</a> </li>
	    <li class="nav-item"><a class="nav-link stroke_btn" href="#whitepaper">Whitepaper</a> </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
    </div>
  )
}

export default Header