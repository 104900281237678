import React, { useEffect, useState } from 'react'
import Header from '../Component/Header'
import global_network from '../images/global_network.png'
import applications from '../images/applications.png'
import content from '../images/content.png'
import advertising from '../images/advertising.png'
import digital from '../images/digital.png'
import shadow from '../images/shadow.png'
import chart1 from '../images/chart1.png'
import chart2 from '../images/chart2.png'
import whitepaper from '../images/whitepaper.png'
import whitepaper2 from '../images/whitepaper2.png'
import feature_1 from '../images/feature_1.png'
import news_img from '../images/news_img.png'
import blockchain from '../images/blockchain.png'
import yellowshadow from '../images/yellowshadow.png'
import Team from '../images/team.png'
import Footer from '../Component/Footer'
import WOW from 'wowjs';
import 'wowjs/css/libs/animate.css';

const Home = () => {


  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  const [timeLeft, setTimeLeft] = useState({
    days: 186,
    hours: 17,
    minutes: 37,
    seconds: 20
  });

  let intervalId;

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateTimeLeft();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const updateTimeLeft = () => {
    setTimeLeft(prevTimeLeft => {
      let { days, hours, minutes, seconds } = prevTimeLeft;

      seconds--;
      if (seconds < 0) {
        seconds = 59;
        minutes--;
        if (minutes < 0) {
          minutes = 59;
          hours--;
          if (hours < 0) {
            hours = 23;
            days--;
            if (days < 0) {
              clearInterval(intervalId);
            }
          }
        }
      }

      return {
        days,
        hours,
        minutes,
        seconds
      };
    });
  };


  return (
    <>
      <Header />

      <section id="top_banner">
        <div class="container">
          <div class="row">
            <div class="col-md-6 banner_txt wow fadeInLeft mt-3 pe-5" data-wow-duration="1.5s">
              <h1 style={{ fontSize: "54px"}}>Infrastructure to support a shared virtual world</h1>
              <p style={{ color:"#515152"}}>BHARAT is a virtual reality platform powered by the Ethereum blockchain.</p>


              <a href="#" class="green_btn">Get Started</a>
            </div>
            <div class="col-md-6 img-wrapper">
              <div class="countdown_time">
                <h3>ICO End in</h3>
                <div class="clock-countdown">
                  <div class="site-config" data-date="09/08/2019 00:00:00" data-date-timezone="+0"></div>
                  <div class="coundown-timer d-flex justify-content-center">
                    <div class="single-counter  d-flex flex-column"><h4><span class="days count-time gradient-color">{timeLeft.days}</span></h4><span class="normal text-black">Days</span></div>
                    <div class="single-counter  d-flex flex-column"><h4><span class="hours count-time gradient-color">{timeLeft.hours}</span></h4><span class="normal text-black">Hours</span></div>
                    <div class="single-counter  d-flex flex-column"><h4><span class="minutes count-time gradient-color">{timeLeft.minutes}</span></h4><span class="normal text-black">Minutes</span></div>
                    <div class="single-counter  d-flex flex-column"><h4><span class="seconds count-time gradient-color">{timeLeft.seconds}</span></h4><span class="normal text-black">Seconds</span></div>
                  </div>
                </div>
                <div class="count-time_btn">
                  <a href="#" title="" class="gradient-color"><span>Sign up &amp; Join our sale List</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="home_wave">
        </div>

        <img src={feature_1} alt="img" class="shade_bg" />
        <img src={yellowshadow} alt="img" class="shadow_img" />

      </section>



      {/* <!-- About Us section start -->  */}
      <section id="about-us">
        <div class="container">
          <div class="row">
            <div class="col-md-6 wow fadeInUp mt-5" data-wow-duration="3s">

              <h1 class="wow fadeInUp text-white" data-wow-duration="2s" data-wow-delay=".3s" >About Us</h1>
              <br />
              <p>At Bharat, we are redefining the digital asset landscape with a cutting-edge cryptocurrency token built on the Binance Smart Chain. Our mission is to empower individuals and businesses worldwide by providing a secure, scalable, and efficient platform for global transactions. With a fixed supply of 1,000,000,000,000 tokens, Bharat is designed to offer stability, value, and a wide range of applications, from peer-to-peer transactions and staking rewards to in-game purchases and merchant payment solutions.</p>
            </div>
            <div class="col-md-6 points wow fadeInUp" data-wow-duration="10s">
              <img class="banner-img" src={global_network} alt="" />
            </div>

          </div>

        </div>
      </section>
      {/* <!-- About Us section end --> */}




      {/* <!-- feature section start -->  */}
      <section id="feature" style={{background:"white"}}>
        <img src={shadow} alt="img" class="left_shine" />
        <div class="container">
          <div class="row">
            <div class="col-12 heading text-center wow fadeInUp" data-wow-duration="1.5s">
              <h1 class="wow fadeInUp  text-black" data-wow-duration="2s" data-wow-delay=".3s" >Use Cases</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 feature_bx wow fadeInUp" data-wow-duration="2s">
              <div class="outer_bx d-flex">
                <div class="icon_bx">
                  <div class="gradient_bx">
                    <img src={applications} alt="img" />
                  </div>
                </div>
                <div class="feature_info">
                  <h3> Peer-to-Peer Transactions</h3>
                  <p style={{ color:"#656566"}}>Bharat facilitates seamless and secure peer-to-peer transactions, allowing users to transfer value quickly and with minimal fees. Whether sending money to family and friends or making payments for goods and services, Bharat ensures a reliable and efficient transaction process.</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 feature_bx wow fadeInUp" data-wow-duration="6s">
              <div class="outer_bx d-flex">
                <div class="icon_bx">
                  <div class="gradient_bx">
                    <img src={content} alt="img" />
                  </div>
                </div>
                <div class="feature_info">
                  <h3> Staking Rewards</h3>
                  <p style={{ color:"#656566"}}>Users can participate in our staking program to earn rewards by holding and staking Bharat tokens. This incentivizes long-term holding and contributes to the stability and growth of our ecosystem.</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 feature_bx wow fadeInUp" data-wow-duration="10s">
              <div class="outer_bx d-flex">
                <div class="icon_bx">
                  <div class="gradient_bx">
                    <img src={advertising} alt="img" />
                  </div>
                </div>
                <div class="feature_info">
                  <h3>In-Game Purchases</h3>
                  <p style={{ color:"#656566"}}>Bharat is integrated into various gaming platforms, enabling gamers to use Bharat tokens for in-game purchases, character upgrades, and other digital assets. This creates a unified and versatile gaming experience.</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 feature_bx wow fadeInUp" data-wow-duration="14s">
              <div class="outer_bx d-flex">
                <div class="icon_bx">
                  <div class="gradient_bx">
                    <img src={digital} alt="img" />
                  </div>
                </div>
                <div class="feature_info">
                  <h3>Merchant Payments</h3>
                  <p style={{ color:"#656566"}}>Businesses can adopt Bharat as a payment method, offering their customers a secure and convenient way to pay for products and services. This opens new avenues for merchants to tap into the growing cryptocurrency market.</p>
                </div>
              </div>
            </div>


          </div>
        </div>
      </section>
      {/* <!-- feature section end --> */}



      {/* <!-- feature section start -->  */}
      {/* <section id="address_link">
        <div class="container text-white text-left wow fadeInUp">
          <h1 className='text-center'>Our Project & Ecosystem</h1>
          <br></br>
          <div class="row">
            <div class="col-md-6 feature_bx wow fadeInUp" data-wow-duration="2s">
              <div class="outer_bx">
                <div class="feature_info">
                  <h3>1. Token Development</h3>
                  <p>We have successfully developed the Bharat token on the Binance Smart Chain, ensuring high scalability and low transaction costs. Our focus is on continuous enhancement and expanding the token's capabilities.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 feature_bx wow fadeInUp" data-wow-duration="2s">
              <div class="outer_bx">
                <div class="feature_info">
                  <h3>2. Tokenomics</h3>
                  <p>Bharat has a fixed supply of 500,00,000,000 tokens, designed to ensure scarcity and value appreciation over time. Our transparent tokenomics model ensures fair distribution and incentivizes community participation.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 feature_bx wow fadeInUp" data-wow-duration="2s">
              <div class="outer_bx">
                <div class="feature_info">
                  <h3>3. Token Listing on Exchanges</h3>
                  <p>We are actively working towards listing Bharat on major cryptocurrency exchanges to enhance liquidity and accessibility for our users. Stay tuned for announcements on upcoming listings and trading pairs.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 feature_bx wow fadeInUp" data-wow-duration="2s">
              <div class="outer_bx">
                <div class="feature_info">
                  <h3>4. Staking Platform</h3>
                  <p>Our staking platform allows users to lock their Bharat tokens and earn attractive rewards. This promotes long-term holding and supports the stability of our token.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 feature_bx wow fadeInUp" data-wow-duration="2s">
              <div class="outer_bx">
                <div class="feature_info">
                  <h3>5. Gaming Integration</h3>
                  <p>We are partnering with leading gaming platforms to integrate Bharat as a utility token for in-game purchases and rewards. This expands the use cases for Bharat and attracts a diverse user base.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 feature_bx wow fadeInUp" data-wow-duration="2s">
              <div class="outer_bx">
                <div class="feature_info">
                  <h3>6. Decentralized Exchange (DEX)</h3>
                  <p>Our future plans include launching a decentralized exchange (DEX) to provide users with a secure and transparent platform for trading Bharat and other cryptocurrencies.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 feature_bx wow fadeInUp" data-wow-duration="2s">
              <div class="outer_bx">
                <div class="feature_info">
                  <h3>7. Blockchain Development</h3>
                  <p>We are committed to advancing blockchain technology and are exploring the development of our proprietary blockchain. This will enhance the capabilities of Bharat and support a wide range of applications.</p>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section> */}
      {/* <!-- feature section end --> */}


      {/* <!-- roadmap section start --> */}
      <section id="roadmap">
        <img src={feature_1} alt="img" class="shade_bg" />
        <div class="container text-white">
          <div class="row">
            <div class="col-12 heading text-center wow fadeInUp " data-wow-duration="1.5s">
              <h1 class="wow fadeInUp  text-white" data-wow-duration="2s" data-wow-delay=".3s" >Roadmap</h1>
              <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium </p>
            </div>
          </div>

          <div class="timeline mt-5">
            <div class="timeline_info left wow fadeInUp" data-wow-duration="2s">
              <div class="content">
                <h4>Nov, 2021</h4>
                <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut.</p>
              </div>

              <span><b></b></span>
            </div>
            <div class="timeline_info right wow fadeInUp" data-wow-duration="2.5s">
              <div class="content">
                <h4>Nov, 2021</h4>
                <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci.</p>
              </div>
              <span><b></b></span>
            </div>
            <div class="timeline_info left wow fadeInUp" data-wow-duration="3s">
              <div class="content">
                <h4>Nov, 2021</h4>
                <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut.</p>
              </div>
              <span><b></b></span>
            </div>
            <div class="timeline_info right wow fadeInUp" data-wow-duration="3.5s">
              <div class="content">
                <h4>Nov, 2021</h4>
                <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci.</p>
              </div>
              <span><b></b></span>
            </div>
            <div class="timeline_info left wow fadeInUp" data-wow-duration="4s">
              <div class="content">
                <h4>Nov, 2021</h4>
                <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut.</p>
              </div>
              <span><b></b></span>
            </div>

          </div>

        </div>
      </section>
      {/* <!-- roadmap section end --> */}

      {/* <!-- Token Distribution section start --> */}
      <section id='token-distribution' class="token-distribution-sec">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 heading text-center">
              <h1 class="wow fadeInUp  text-black" data-wow-duration="2s" data-wow-delay=".3s" >Token Distribution</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 wow fadeInUp" data-wow-duration="2s" >
              <div class="row chart-gap">
                <div class="col-sm-7 ml-lg-auto"><img src={chart1} alt="" />
                  <h3 class="xs-single-title">ICO Details</h3>
                </div>
                <div class=" col-sm-5">
                  <ul class="chart-label">
                    <li>
                      <span class="chart-bg1">15 Days</span> Pre ICO time period
                    </li>
                    <li>
                      <span class="chart-bg2">10%</span> POST ICO 2 Stage Bonus
                    </li>
                    <li>
                      <span class="chart-bg3">20%</span> POST ICO 1 Stage Bonus
                    </li>
                    <li>
                      <span class="chart-bg4">30%</span> PRE ICO 1 Stage Bonus
                    </li>
                  </ul>
                </div>

              </div>

            </div>
            <div class="col-lg-6 wow fadeInUp" data-wow-duration="6s" >
              <div class="row chart-gap">
                <div class="col-sm-7 ml-lg-auto">
                  <img src={chart2} alt="" />
                  <h3 class="xs-single-title">Coins Distribution</h3>
                </div>
                <div class="col-sm-5">
                  <ul class="chart-label">
                    <li>
                      <span class="chart-bg1">15 TOKEN </span> Per Refer Reward
                    </li>
                    <li>
                      <span class="chart-bg2">1,00,00,000</span> Public Supply
                    </li>
                    <li>
                      <span class="chart-bg3">10,00,00,000</span> Total Supply of coins
                    </li>
                    <li>
                      <span class="chart-bg4">90%</span> Every Wallet
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </div>

        </div>
      </section>
      {/* <!-- Token Distribution section end --> */}



      {/* <!-- ICO section start -->  */}
      <section id="token_sale_06">
        <img src={feature_1} alt="img" class="shade_bg" />
        <div class="container">
          <div class="row">
            <div class="col-sm-12 heading">
              <h1 class="wow fadeInUp  text-white" data-wow-duration="2s" data-wow-delay=".3s" >Token Sale / ICO</h1>

            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="row pricing_items">
                <div class="col-sm-4 col-md-3 single-wrapper wow fadeInUp " data-wow-duration="2s" data-wow-delay=".2s" >
                  <div class="pricing_single">
                    <div class="offer_price">
                      <h4>Price $0.80 <span class="shape_1"></span></h4>
                    </div>
                    <div class="offer_details">

                      <h3>15 Apr 2022</h3>
                      <p>Start Date</p>
                    </div>
                    <div class="sale">
                      <a href="#" title="">Pre Sale</a>
                    </div>

                  </div>
                </div>
                <div class="col-sm-4 col-md-3 single-wrapper wow fadeInUp " data-wow-duration="4s" data-wow-delay=".2s" >
                  <div class="pricing_single">
                    <div class="offer_price">
                      <h4>Min. Purchase $70<span class="shape_1"></span></h4>
                    </div>
                    <div class="offer_details">
                      <h3>15 Apr 2022</h3>
                      <p>Start Date</p>
                    </div>
                    <div class="sale">
                      <a href="#" title="">Pre Sale</a>
                    </div>

                  </div>
                </div>
                <div class="col-sm-4 col-md-3 single-wrapper wow fadeInUp " data-wow-duration="6s" data-wow-delay=".2s" >
                  <div class="pricing_single">
                    <div class="offer_price">
                      <h4>Price $1.00<span class="shape_1"></span></h4>
                    </div>
                    <div class="offer_details">
                      <h3>15 Apr 2022</h3>
                      <p>Start Date</p>
                    </div>
                    <div class="sale">
                      <a href="#" title="">Public Sale</a>
                    </div>

                  </div>
                </div>
                <div class="col-sm-4 col-md-3 single-wrapper wow fadeInUp " data-wow-duration="8s" data-wow-delay=".2s" >
                  <div class="pricing_single">
                    <div class="offer_price">
                      <h4>Price $1.00<span class="shape_1"></span></h4>
                    </div>
                    <div class="offer_details">
                      <h3>15 Apr 2022</h3>
                      <p>End Date</p>
                    </div>
                    <div class="sale">
                      <a href="#" title="">Public Sale</a>
                    </div>

                  </div>
                </div>

              </div>
            </div>
            <div class="col-md-12">
              <div class="row pricing_bottom">
                <div class="col-sm-6">
                  <div class="pricing_list_wrapper">
                    <ul class="list-unstyled item_list_2  wow fadeInUp " data-wow-duration="2s" data-wow-delay=".3s">
                      <li><h3>Token Name</h3> <span>Bharat</span></li>
                      <li><h3>Token Short Form</h3> <span>BHRT</span></li>
                    </ul>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="pricing_list_wrapper">
                    <ul class="list-unstyled item_list_3  wow fadeInUp " data-wow-duration="2s" data-wow-delay=".4s" >
                      <li><h3>Token Supply</h3> <span>1 lakh cr</span></li>
                      <li><h3>Token Price</h3> <span>Rs 0.1 </span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </section>
      {/* <!-- ICO section end --> */}


      {/* <!--whitepaper section start -->  */}
      <section id="whitepaper">
        <img src={shadow} alt="img" class="right_shine" />
        <div class="container">
          <div class="row">
            <div class="col-12 heading text-center wow fadeInUp" data-wow-duration="1.5s">
              <h1 class="wow fadeInUp  text-black" data-wow-duration="2s" data-wow-delay=".3s" >Download Our Whitepaper</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-md-10 m-auto">
              <div class="row">
                <div class="col-md-7 whitepaper_info wow fadeInUp" data-wow-duration="1.5s">
                  <p>Gain in-depth insights into the Bharat project by downloading our comprehensive whitepaper. The whitepaper details our mission, vision, tokenomics, use cases, development roadmap, and more. It serves as a valuable resource for investors, developers, and anyone interested in understanding the full potential of Bharat.</p>
                  <br />
                  <p>Explore how Bharat is set to revolutionize the digital asset landscape and join us in our journey towards a more inclusive and innovative financial future.</p>

                </div>
                <div class="col-md-5 whitepaper_img wow fadeInUp mt-4" data-wow-duration="2.5s">
                  <img src={whitepaper} alt="img" />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-center wow fadeInUp" data-wow-duration="1.5s">
              <a href="#" class="green_btn mt-5"> Download Whitepaper </a>
            </div>
          </div>

        </div>
      </section>
      {/* <!--whitepaper section end -->  */}

      {/* <!-- Our People section start --> */}
      {/* <section id="people" class="dark_bg">
        <div class="container">
          <div class="row">
            <div class="col-12 heading text-center mb-5">
              <h1>Team</h1>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 col-sm-12  wow fadeInUp text-white" data-wow-duration="1.5s">
              <a href="#">
                <div class="user_info text-center">
                  <img src={Team} alt="img" />
                  <h4>Founder Name</h4>
                  <p><small>Founder/CEO</small></p>
                  <p className='contentbox'> <small>Name is a finance expert with vast experience in alternative funding and crypto trading. He holds a strong conviction in the potential of Web3 industry.</small></p>
                </div>
              </a>
            </div>

            <div class="col-md-4 col-sm-12  wow fadeInUp" data-wow-duration="2s">
              <a href="#">
                <div class="user_info text-center">
                  <img src={Team} alt="img" />
                  <h4>Co-Founder Name</h4>
                  <p><small>Co-Founder/CFO</small></p>
                  <p className='contentbox'> <small>Name is a tech expert with 5+years of experience in application development with a strong back ground in languages like SQL, Python, C++,etc.</small></p>
                </div>
              </a>
            </div>

            <div class="col-md-4 col-sm-12  wow fadeInUp" data-wow-duration="2.5s">
              <a href="#">
                <div class="user_info text-center">
                  <img src={Team} alt="img" />
                  <h4>Co-Founder name</h4>
                  <p><small>Co-Founder/CTO</small></p>
                  <p className='contentbox'> <small>Name is a finance expert with vast experience in alternative funding and crypto trading. He holds a strong conviction in the potential of Web3 industry.</small></p>
                </div>
              </a>
            </div>


          </div>

        </div>
      </section> */}
      {/* <!-- Our People section end --> */}


      <Footer />



    </>

  )
}

export default Home